import { ReactSession } from 'react-client-session';
export const API_KEY_SESSION_KEY = 'InAuditApiKey';
export const LOGGED_USER_SESSION_KEY = 'InAuditSession';
export const AXIOS_CONFIG_SESSION_KEY = 'InAuditAxiosConfig';
export const AXIOS_CALL_RESULT_SESSION_KEY = 'InAuditAxiosCallResult';

export const getSessionValue = (key, defaultValue) => {
  ReactSession.setStoreType('sessionStorage');
  let apiKey = ReactSession.get(key);
  if (!apiKey) ReactSession.set(key, defaultValue);
  return ReactSession.get(key);
};

export const setSessionValue = (key, value) => {
  ReactSession.setStoreType('sessionStorage');
  ReactSession.set(key, value);
};
