import { Stack, useTheme } from '@mui/material';
import React from 'react';
import logo from '../../assets/images/logo.png';
import logoPartner from '../../assets/images/logo-integrapp-negative.svg';

function AppLogo({ size, partnerSize, direction, sx }) {
  const theme = useTheme();
  const dir = direction ? direction : 'column';
  const sStyle = { ...sx };
  const pStyle = dir === 'column' ? { height: partnerSize, width: 'auto' } : { height: 'auto', width: partnerSize };
  let lStyle = dir === 'column' ? { height: size, width: 'auto' } : { height: 'auto', width: size };
  lStyle = {
    ...lStyle,
    'clip-path': 'circle(50%)',
    py: theme.spacing(1)
  };

  return (
    <Stack spacing={theme.spacing(2)} justifyContent="center" alignItems="center" direction={dir} sx={{...sStyle}}>
      {partnerSize && <img src={logoPartner} alt={'logo'} style={pStyle} />}
      {size && <img src={logo} alt={'logo'} style={lStyle} />}
    </Stack>
  );
}

export default AppLogo;
