import { useSelector } from 'react-redux';
import { CustomAlert } from './CustomAlerts';
import { CustomConfirmDialog } from './CustomConfirmDialog';

export const AlertsTree = () => {
  const customAlerts = useSelector((state) => state.errors.customAlertQueue);
  const alertQueue = useSelector((state) => state.errors.alertQueue);
  const customConfirm = useSelector((state) => state.errors.customConfirmQueue);

  return (
    <>
      {customAlerts && customAlerts.length !== 0 && (
        <>
          {customAlerts.map((item, index) => {
            return (
              <CustomAlert
                key={'customAlerts' + index}
                item={item}
                open={item.open}
                message={item.message}
                severity={item.severity}
                handleHide={item.handleHide}
              />
            );
          })}
        </>
      )}
      {customConfirm && customConfirm.length !== 0 && (
        <>
          {customConfirm.map((item, index) => {
            return (
              <CustomConfirmDialog
                key={'customConfirm' + index}
                item={item}
                title={item.title}
                open={item.open}
                message={item.message}
                handleHide={item.handleHide}
                handleCancel={item.handleCancel}
                handleConfirm={item.handleConfirm}
              />
            );
          })}
        </>
      )}
      {alertQueue && alertQueue.length !== 0 && (
        <>
          {alertQueue.map((item, index) => {
            if (!item.finished) return <></>;
            if (!item.responseData)
              return <CustomAlert key={'alertQueue' + index} item={item} open={true} message={'Risposta chiamata API non valida.'} severity={item.severity} />;
            if (item.error.code !== 0 || item.anyError === true) {
              let message = 'Errore server: ' + item.error.code + ' ' + item.error.message;
              return <CustomAlert key={'alertQueue' + index} item={item} open={true} message={message} severity={item.severity} />;
            }
            if (item.error.code === 0 && !item.showOnlyError) {
              let message = item.error.message;
              return <CustomAlert key={'alertQueue' + index} item={item} open={true} message={message} severity={item.severity} />;
            }
          })}
        </>
      )}
    </>
  );
};
