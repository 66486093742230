import { Box, Button, Stack, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { ButtonIcon } from '../icons/ButtonIcon';

export const ActionButton = ({
  color,
  variant,
  action,
  size,
  icon,
  iconSize,
  label,
  tooltip,
  disabled,
  loading,
  fullWidth,
  vertical,
  smallHeight,
  sx,
  ...otherProps
}) => {
  const theme = useTheme();

  let isDisabled = disabled ?? false;
  let sLabel = label ?? '';
  let sTooltip = tooltip ?? '';
  let hasTooltip = !isDisabled && sTooltip !== '';
  let isLoading = loading ?? false;
  let sIcon = isLoading ? 'loading' : icon;
  let isFullWidth = fullWidth ?? false;
  let isVertical = vertical ?? false;
  let dir = isVertical ? 'column' : 'row';
  let justifyContent = isVertical ? 'center' : 'left';
  let alignItems = isVertical ? 'flex-start' : 'center';
  let fullWidthSx = isFullWidth ? { width: '100%' } : {};
  let buttonSx = {
    ...sx,
    display: 'flex',
    direction: dir,
    justifyContent: justifyContent + ' !important',
    alignItems: alignItems + ' !important'
  };
  if (smallHeight) buttonSx = { ...buttonSx, maxHeight: '30px !important', minHeight: '30px !important' };

  const getButton = () => {
    return (
      <Button
        color={color}
        variant={variant}
        onClick={action}
        size={size}
        disabled={isDisabled || isLoading}
        fullWidth={fullWidth}
        justifyContent={justifyContent}
        alignItems={alignItems}
        sx={buttonSx}
        {...otherProps}
      >
        <Stack direction={dir} spacing={theme.spacing(0.2)} justifyContent={justifyContent} alignItems={alignItems}>
          <ButtonIcon icon={sIcon} iconSize={iconSize} sx={{ mr: theme.spacing(0.7) }} />
          {sLabel}
        </Stack>
      </Button>
    );
  };

  return !hasTooltip ? (
    <>{getButton()}</>
  ) : (
    <Tooltip title={tooltip}>
      <Box sx={fullWidthSx}>{getButton()}</Box>
    </Tooltip>
  );
};
