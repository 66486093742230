import { Menu, MenuItem, Stack, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { ActionButton } from '../buttons/ActionButton';
import { ToolButton } from '../buttons/ToolButton';
import { commonClassNames } from '../../themes/Styles';

export const AddButton = ({ addAction, label, ...props }) => {
  const buttonClicked = () => {
    addAction();
  };

  return (
    <ActionButton
      color="primary"
      variant="contained"
      action={buttonClicked}
      size="medium"
      icon="new"
      iconSize="small"
      tooltip={label ?? 'Nuovo'}
      label={label ?? 'Nuovo'}
      {...props}
    />
  );
};

export const EditButton = ({ editAction, editLabel, editTooltip, ...props }) => {
  const label = editLabel ?? 'Modifica';
  const tooltip = editTooltip ?? 'Modifica';

  const buttonClicked = () => {
    editAction();
  };

  return (
    <ToolButton
      color="primary"
      variant="contained"
      action={buttonClicked}
      size="medium"
      icon="edit"
      iconSize="small"
      tooltip={tooltip}
      label={label}
      {...props}
    />
  );
};

export const DeleteButton = ({ deleteAction, ...props }) => {
  const buttonClicked = () => {
    deleteAction();
  };

  return (
    <ToolButton
      color="error"
      variant="contained"
      action={buttonClicked}
      size="medium"
      icon="delete"
      iconSize="small"
      tooltip="Elimina"
      label="Elimina"
      {...props}
    />
  );
};

export const CustomButton = ({ customAction, customDisabled, customIcon, customTooltip, customLabel, color, ...props }) => {
  const buttonClicked = () => {
    customAction();
  };

  return (
    <ToolButton
      color={color}
      variant="contained"
      action={buttonClicked}
      size="medium"
      icon={customIcon}
      iconSize="small"
      tooltip={customTooltip}
      disabled={customDisabled}
      label={customLabel}
      {...props}
    />
  );
};

export const ActionsToolBar = ({
                                 hasEdit,
                                 editAction,
                                 editDisabled,
                                 editLabel,
                                 editTooltip,
                                 hasDelete,
                                 deleteAction,
                                 deleteDisabled,
                                 customActions,
                                 ...props
                               }) => {
  const theme = useTheme();
  const commonClasses = commonClassNames(theme);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  customActions = customActions ?? [];
  let actionsCount = 0;
  actionsCount += hasEdit ? 1 : 0;
  actionsCount += hasDelete ? 1 : 0;
  actionsCount += customActions.length;
  const maxActionsCount = 3;

  const setTarget = (target) => {
    setAnchorEl(target);
  };

  const openActionsMenuClick = () => {};

  const closeActionsMenuClick = () => {
    setAnchorEl(null);
  };

  const bWithContextual = actionsCount > maxActionsCount;

  return (
    <Stack direction={'row'} spacing={theme.spacing(1)} sx={{ pt: theme.spacing(1) }}>
      {!bWithContextual ? (
        <>
          {hasEdit && (
            <EditButton
              editAction={editAction}
              editLabel={editLabel}
              editTooltip={editTooltip}
              disabled={editDisabled ?? false}
              {...props}
            />
          )}
          {hasDelete && <DeleteButton deleteAction={deleteAction} disabled={deleteDisabled ?? false} {...props} />}
          {customActions.map((item) => {
            return (
              <CustomButton
                key={'custom-button-' + item.action}
                customAction={item.action}
                disabled={item.disabled ?? false}
                customIcon={item.icon}
                customTooltip={item.tooltip}
                color={item.color ?? 'info'}
                customLabel={item.label}
              />
            );
          })}
        </>
      ) : (
        <>
          <CustomButton
            key={'more-button'}
            customAction={openActionsMenuClick}
            disabled={false}
            customIcon={'more-vert'}
            customTooltip={'Azioni'}
            color={'info'}
            setTarget={setTarget}
            customLabel={'Azioni'}
          />
          <Menu
            id="more-menu"
            aria-labelledby="more-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={closeActionsMenuClick}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          >
            <Stack direction={'column'}>
              {hasEdit && (
                <MenuItem onClick={closeActionsMenuClick} sx={commonClasses.popupMenuItem} disabled={editDisabled}>
                  <EditButton
                    editAction={editAction}
                    editLabel={editLabel}
                    editTooltip={editTooltip}
                    disabled={editDisabled ?? false}
                    contextButton
                    {...props}
                  />
                </MenuItem>
              )}
              {hasDelete && (
                <MenuItem onClick={closeActionsMenuClick} sx={commonClasses.popupMenuItem} disabled={deleteDisabled}>
                  <DeleteButton deleteAction={deleteAction} disabled={deleteDisabled ?? false} contextButton {...props} />
                </MenuItem>
              )}
              {customActions.map((item) => {
                return (
                  <MenuItem
                    onClick={closeActionsMenuClick}
                    key={'custom-menu-item-' + item.action}
                    sx={commonClasses.popupMenuItem}
                    disabled={item.disabled ?? false}
                  >
                    <CustomButton
                      key={'custom-button-' + item.action}
                      customAction={item.action}
                      disabled={item.disabled ?? false}
                      customIcon={item.icon}
                      customTooltip={item.tooltip}
                      color={item.color ?? 'info'}
                      customLabel={item.label}
                      contextButton
                    />
                  </MenuItem>
                );
              })}
            </Stack>
          </Menu>
        </>
      )}
    </Stack>
  );
};
