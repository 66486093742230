import { Box, Tooltip } from '@mui/material';
import React from 'react';
import { ActionButton } from './ActionButton';
import { CustomIconButton } from './CustomIconButton';

export const ToolButton = ({
  color,
  variant,
  action,
  size,
  icon,
  iconSize,
  tooltip,
  disabled,
  setTarget,
  label,
  contextButton,
  ...otherProps
}) => {
  let isDisabled = disabled ?? false;
  let sTooltip = tooltip ?? '';
  let hasTooltip = !isDisabled && sTooltip !== '';
  let isContextButton = contextButton ?? false;
  let isFullWidth = otherProps.fullWidth ?? false;
  let toolTipSx = isFullWidth ? { width: '100%' } : {};

  const onClick = (e) => {
    e.preventDefault();
    if (setTarget) setTarget(e.currentTarget);
    action();
  };

  const getContextButton = () => {
    return (
      <ActionButton
        color={color}
        variant={'outlined'}
        action={onClick}
        size={size}
        icon={icon}
        iconSize={iconSize}
        label={label}
        fullWidth
        smallHeight
        {...otherProps}
      />
    );
  };

  const getIconButton = () => {
    return (
      <CustomIconButton
        color={color}
        variant={variant}
        onClick={onClick}
        size={size}
        disabled={isDisabled}
        {...otherProps}
        icon={icon}
        iconSize={iconSize}
      />
    );
  };

  return isContextButton ? (
    <>{getContextButton()}</>
  ) : !hasTooltip ? (
    <>{getIconButton()}</>
  ) : (
    <Tooltip title={tooltip}>
      <Box sx={toolTipSx}>{getIconButton()}</Box>
    </Tooltip>
  );
};
