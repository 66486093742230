import { grey } from '@mui/material/colors';

export const notLoggedClassNames = (theme) => {
  return {
    notLoggedContainer: {
      minHeight: '100vh'
    },
    notLoggedContent: {
      minHeight: {
        xs: 'calc(100vh - 134px)',
        md: 'calc(100vh - 300px)'
      },
      [theme.breakpoints.down('lg')]: {
        p: theme.spacing(1)
      },
      [theme.breakpoints.up('lg')]: {
        p: theme.spacing(1)
      }
    },
    notLoggedMainCard: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText + ' !important',
      minWidth: { xs: 350, lg: 500 },
      maxWidth: { xs: 350, lg: 500 },
      margin: { xs: 1 },
      '& > *': {
        flexGrow: 1,
        flexBasis: '50%'
      },
      '& a': {
        color: theme.palette.primary.contrastText + ' !important'
      }
    },
    notLoggedMainBox: {
      color: theme.palette.primary.contrastText + ' !important',
      padding: { xs: 2, sm: 3, md: 4, xl: 5 }
    },
    loginContainer: {}
  };
};

export const loggedClassNames = (theme) => {
  return {
    loggedContainer: {
      minWidth: '95vw',
      maxWidth: '100vw',
      minHeight: '50vh'
    },
    loggedContent: {
      minWidth: '95vw',
      maxWidth: '100vw',
      maxHeight: '99vh',
      overflow: 'auto'
    },
    loggedCardContent: {
      p: theme.spacing(1) + ' !important',
      borderWidth: theme.spacing(0.2),
      borderStyle: 'solid',
      borderColor: theme.palette.primary.dark,
      minWidth: '95vw',
      // maxWidth: '95vw',
      minHeight: '75vh',
      // maxHeight: '99vh',
      overflow: 'auto'
    },
    detailsContainer: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      boxShadow: theme.spacing(24),
      padding: theme.spacing(4),
      minHeight: '70vh',
      maxHeight: '95vh',
      minWidth: '70vw',
      overflowY: 'scroll !important'
    },
    detailsContent: {
      minHeight: '65vh',
      maxWidth: '98vw'
    },
    smallDetailsContainer: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      border: '1px solid #000',
      boxShadow: theme.spacing(24),
      padding: theme.spacing(4),
      minHeight: '50vh',
      maxHeight: '95vh',
      minWidth: '50vw',
      overflowY: 'scroll !important'
    },
    smallDetailsContent: {
      minHeight: '50vh',
      maxWidth: '50vw'
    },
    extraSmallDetailsContainer: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      border: '1px solid #000',
      boxShadow: theme.spacing(24),
      padding: theme.spacing(4),
      minHeight: '25vh',
      maxHeight: '95vh',
      minWidth: '25vw',
      overflowY: 'scroll !important'
    },
    extraSmallDetailsContent: {
      minHeight: '25vh',
      maxWidth: '25vw'
    },
    detailsContainerSmall: {},
    roomsPlannerWidth: {
      width: '100% important'
    },
    roomTitle: {
      backgroundColor: theme.palette.primary.dark + ' !important',
      color: theme.palette.primary.contrastText + ' !important'
    },
    roomContainer: {
      color: theme.palette.primary.dark + '!important',
      maxHeight: '55vh',
      overflowX: 'auto !important',
      position: 'relative',
      overflow: 'auto',
      '& ul': { padding: 0 },
      borderRadius: theme.spacing(1),
      borderTop: '1px solid ' + theme.palette.grey['500'],
      borderLeft: '1px solid ' + theme.palette.grey['500'],
      borderRight: '1px solid ' + theme.palette.primary.dark,
      borderBottom: '2px solid ' + theme.palette.primary.dark,
      width: '100% !important'
    },
    roomList: {
      px: theme.spacing(0.1),
      py: theme.spacing(0.5),
      width: '100% !important',
      maxHeight: '55vh',
      overflowX: 'auto !important',
      position: 'relative',
      overflow: 'auto'
    },
    roomTag: {
      cursor: 'pointer',
      pr: theme.spacing(0),
      py: theme.spacing(2),
      width: '100% !important'
    },
    roomTagItem: {
      minHeight: '50px'
    },
    roomTagButton: {
      mr: theme.spacing(1)
    },
    roomCurrentTag: {
      mr: theme.spacing(1),
      fontWeight: 'bolder',
      textDecoration: 'underline'
    },
    roomSlot: {
      boxShadow:
        '0px 2px 1px -1px ' +
        theme.palette.secondary.dark +
        ', ' +
        '0px 1px 1px 0px ' +
        theme.palette.secondary.main +
        ', ' +
        '0px 1px 4px 0px ' +
        theme.palette.secondary.light +
        '',
      width: '100% !important;'
    },
    roomSlotTitle: {},
    roomSlotContent: {
      padding: theme.spacing(1)
    },
    bookingDetailHeader: {
      p: theme.spacing(1)
    },
    bookingDetailModal: {
      p: theme.spacing(2),
      minHeight: '12vh',
      maxHeight: '95vh',
      maxWidth: '60vw'
    },
    availabilitiesContainer: {
      minWidth: '65vw'
    },
    availabilityHour: {
      boxShadow:
        '0px 2px 1px -1px ' +
        theme.palette.secondary.dark +
        ', ' +
        '0px 1px 1px 0px ' +
        theme.palette.secondary.main +
        ', ' +
        '0px 1px 4px 0px ' +
        theme.palette.secondary.light +
        '',
      px: theme.spacing(2),
      py: theme.spacing(2)
    },
    availabilityDay: {
      minHeight: '5vw',
      minWidth: '7vw',
      maxWidth: '7vw',
      backgroundColor: theme.palette.primary.dark + ' !important',
      color: theme.palette.primary.contrastText + ' !important',
      textAlign: 'center',
      cursor: 'pointer',
      textDecoration: 'none'
    },
    selectedAvailabilityDay: {
      minHeight: '5vw',
      minWidth: '7vw',
      maxWidth: '7vw',
      backgroundColor: theme.palette.secondary.main + ' !important',
      color: theme.palette.primary.contrastText + ' !important',
      textAlign: 'center',
      cursor: 'pointer'
    },
    availabilitySlot: {
      minHeight: '15vh',
      overflowY: 'scroll !important'
    },
    roomSlotDetail: {
      minWidth: '25vw',
      maxWidth: '25vw',
      minHeight: '25vh'
    },
    roomSlotDetailTitle: {
      backgroundColor: theme.palette.primary.dark + ' !important',
      color: theme.palette.primary.contrastText + ' !important'
    },
    visitTabButtons: {
      minHeight: '5vh'
    },
    visitTabButton: {
      minWidth: '15vw'
    },
    visitTabContainer: {
      minWidth: '95vw',
      maxWidth: '95vw'
    },
    visitTabPanel: {
      minHeight: '50vh',
      paddingTop: theme.spacing(2)
    },
    visitInfoSticky: {
      position: 'fixed',
      padding: theme.spacing(1),
      elevation: 3,
      top: '12vh',
      left: 'calc(100vw - 25.1vw)',
      minWidth: '25vw',
      maxWidth: '25vw',
      zIndex: 1100,
      boxShadow: theme.spacing(24)
    },
    documentPreview: {
      minWidth: '95vw',
      maxWidth: '95vw',
      maxHeight: '65vh',
      minHeight: '65vh',
      overflow: 'hidden'
    },
    userDetailHeader: {
      minHeight: '12vh'
    },
    userTabButtons: {
      maxHeight: '50vh',
      minHeight: '50vh',
      minWidth: '10vw',
      maxWidth: '10vw'
    },
    userTabPanel: {
      maxHeight: '75vh',
      minHeight: '75vh',
      overflowY: 'auto'
    },
    userImage: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1)
      }
    },
    desktopContainer: {
      p: theme.spacing(4) + ' !important',
      minWidth: '90vw',
      borderWidth: theme.spacing(0.5),
      borderStyle: 'solid',
      borderColor: theme.palette.primary.dark
    },
    desktopNavButton: {
      minHeight: '8vh',
      cursor: 'pointer',
      border: '1px solid',
      padding: theme.spacing(2)
    },
    desktopNavButtonLinkStyle: {
      ml: theme.spacing(4) + '!important',
      textDecoration: 'none'
    },
    desktopButtonIconStyle: {
      marginTop: theme.spacing(2),
      width: '8vw !important',
      height: '8vh !important'
    },
    paginationToolbarHeightSx: {
      height: '40px !important'
    },
    paginationToolbar: {
      height: '40px !important',
      marginBottom: '40px !important'
    },
    registriesToolbarSx: {
      height: '40px !important'
    },
    chartContainerStyle: {
      textAlign: 'center',
      border: '1px solid ' + theme.palette.primary.light
    },
    chartBodyStyle: {
      px: theme.spacing(1),
      py: theme.spacing(2)
    },
    companiesTreeViewContainer: {
      height: 'auto',
      width: '30vw',
      padding: theme.spacing(4)
    },
    companiesTreeView: {},
    companiesTreeViewItem: {},
    customConfirmDialogTitle: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText
    },
    customConfirmDialogMessage: {
      color: theme.palette.primary.dark,
      textAlign: 'center',
      paddingTop: theme.spacing(2)
    },
    customConfirmDialogActions: {
      justifyContent: 'center',
      paddingTop: theme.spacing(2)
    },
    schedulerAccordionHeader: {
      backgroundColor: theme.palette.primary.dark + ' !important',
      color: theme.palette.primary.contrastText + ' !important',
      minHeight: '5vh',
      padding: theme.spacing(2)
    },
    schedulerDayHeader: {
      backgroundColor: theme.palette.primary.dark + ' !important',
      color: theme.palette.primary.contrastText + ' !important',
      minHeight: '12vh',
      minWidth: '11vw',
      maxWidth: '11vw',
      padding: theme.spacing(2)
    },
    schedulerFirstLastColumn: {
      backgroundColor: theme.palette.primary.dark + ' !important',
      color: theme.palette.primary.contrastText + ' !important',
      minHeight: '22vh',
      maxHeight: '22vh',
      minWidth: '11vw',
      maxWidth: '11vw',
      padding: theme.spacing(2),
      cursor: 'pointer'
    },
    schedulerNextPrevWeekButton: {
      backgroundColor: theme.palette.primary.dark + ' !important',
      color: theme.palette.primary.contrastText + ' !important',
      minHeight: '8vh',
      minWidth: '8vw'
    },
    schedulerCell: {
      minHeight: '22vh',
      maxHeight: '22vh',
      minWidth: '11vw',
      maxWidth: '11vw',
      padding: theme.spacing(2),
      overflowY: 'scroll'
    },
    schedulerInterval: {
      borderWidth: theme.spacing(0.5),
      borderStyle: 'solid',
      borderRadius: theme.spacing(0.1),
      padding: theme.spacing(1),
      cursor: 'pointer'
    },
    schedulerIntervalHeader: {
      textTransform: 'uppercase',
      fontWeight: 'bold',
      overflowWrap: 'break-word'
    },
    availabilityCell: {
      minWidth: { xs: 350, lg: 500 }
    },
    tabContainer: {
      minWidth: '90vw',
      maxWidth: '97vw'
    },
    tabButtonsContainer: {
      maxWidth: '97vw',
      minHeight: '5vh',
      maxHeight: '10vh'
    },
    tabButton: {
      minWidth: '6vw'
    },
    tabPanel: {
      maxHeight: '50vh',
      minHeight: '50vh',
      overflowY: 'auto'
    },
    companyTabButtons: {
      minHeight: '5vh'
    },
    companyTabButton: {
      minWidth: '15vw'
    },
    companyTabPanel: {
      minHeight: '50vh',
      paddingTop: theme.spacing(2)
    },
    accordionHeader: {
      backgroundColor: theme.palette.primary.dark + ' !important',
      color: theme.palette.primary.contrastText + ' !important',
      minHeight: '5vh',
      padding: theme.spacing(2)
    },
    auditContainer: {
      minHeight: '60vh',
      maxWidth: '98vw'
    },
    auditStepper: {
      flexGrow: 1,
      minHeight: '5vh',
      py: 3
    },
    auditPaginator: {
      px: theme.spacing(1),
      py: theme.spacing(1)
    },
    auditQuestions: {
      py: theme.spacing(1),
      my: theme.spacing(0.5)
    },
    auditFinish: {
      py: theme.spacing(1)
    },
    auditControls: {
      px: theme.spacing(1),
      py: theme.spacing(1)
    }
  };
};

export const commonClassNames = (theme) => {
  return {
    menuIcon: {
      paddingRight: '2px !important',
      color: theme.palette.primary.main
    },
    desktopIcon: {
      color: theme.palette.primary.main
    },
    logo: {
      maxHeight: 250
    },
    tabPanelBox: {
      pl: theme.spacing(0.5),
      pr: theme.spacing(0.5),
      minHeight: '40vh'
    },
    popupMenuItem: {
      minWidth: '150px !important',
      maxWidth: '250px !important',
      width: '100%',
      px: theme.spacing(0.5),
      alignItems: 'center',
      justifyContent: 'left'
    }
  };
};

export const formClassNames = (theme) => {
  return {
    formControl: {
      marginTop: theme.spacing(2) + ' !important'
    }
  };
};

export const reportingStyleNames = (theme) => {
  return {
    reportingDetailHeader: {
      minHeight: '50vh',
      maxHeight: '95vh',
      minWidth: '70vw'
    },
    reportingTabButtons: {
      maxHeight: '50vh',
      minHeight: '50vh',
      minWidth: '10vw',
      maxWidth: '10vw'
    },
    reportingTabPanel: {
      width: '98%',
      maxHeight: '50vh',
      minHeight: '50vh',
      overflowY: 'auto'
    },
    reportingWrapper: {
      minWidth: '84vw',
      maxWidth: '84vw'
    },
    reportingArea: {
      minHeight: '50vh',
      backgroundColor: grey[200],
      padding: theme.spacing(4)
    },
    reportingToolbar: {
      height: '12vh',
      backgroundColor: theme.palette.primary.dark
    },
    reportingCustomToolbar: {
      backgroundColor: theme.palette.primary.dark
    },
    templateWrapper: {},
    templateArea: {
      minHeight: '50vh',
      backgroundColor: grey[200],
      padding: theme.spacing(4)
    },
    templateToolbar: {
      minHeight: '5vh',
      backgroundColor: theme.palette.primary.dark
    }
  };
};

export const userImageDropZoneStyles = (theme) => {
  return {
    userImageDropzoneBase: {
      padding: '75px',
      borderWidth: 2,
      borderRadius: 2,
      borderColor: grey[500],
      borderStyle: 'dashed',
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.primary.dark,
      outline: 'none',
      transition: 'border .24s ease-in-out'
    },
    smallDropzoneBase: {
      padding: '2px',
      borderWidth: 2,
      borderRadius: 2,
      borderColor: grey[500],
      borderStyle: 'dashed',
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.primary.dark,
      outline: 'none',
      transition: 'border .24s ease-in-out'
    },
    userImageDropzoneActive: { borderColor: theme.palette.primary.dark },
    userImageDropzoneFocused: { borderColor: theme.palette.secondary.dark },
    userImageDropzoneAccepted: { borderColor: theme.palette.success.dark },
    userImageDropzoneRejected: { borderColor: theme.palette.error.dark }
  };
};

export const userPdfDropZoneStyles = (theme) => {
  return {
    userPdfDropzoneBase: {
      padding: '20px',
      borderWidth: 2,
      borderRadius: 2,
      borderColor: grey[500],
      borderStyle: 'dashed',
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.primary.dark,
      outline: 'none',
      transition: 'border .24s ease-in-out'
    },
    userPdfDropzoneActive: { borderColor: theme.palette.primary.dark },
    userPdfDropzoneFocused: { borderColor: theme.palette.secondary.dark },
    userPdfDropzoneAccepted: { borderColor: theme.palette.success.dark },
    userPdfDropzoneRejected: { borderColor: theme.palette.error.dark }
  };
};

export const gridSizeStyle = { height: '65vh', width: '100%' };

export const gridSmallSizeStyle = { height: '30vh', width: '100%' };

export const gridMediumSizeStyle = { height: '45vh', width: '100%' };

export const gridChartSizeStyle = { height: '45vh', width: '98%' };

export const gridExtraSizeStyle = { height: '65vh', width: '80vw' };

export const gridRowHeight = 45;
