import { CircularProgress, Grid, Typography } from '@mui/material';
import React from 'react';

export const CircularWaiting = ({ size, minHeight, text }) => {
  const _size = size ?? 50;
  const h = minHeight ?? '50vh';
  const _text = text ?? 'Operazione in corso, attendere prego';

  return (
    <Grid container direction={'column'} alignItems={'center'} justifyContent={'center'} sx={{ minHeight: h }}>
      <Grid item xs={12}>
        <CircularProgress color="primary" size={_size} />
      </Grid>
      <Grid item xs={12}><Typography> {_text} </Typography></Grid>
    </Grid>
  );
};
