import { Chip, useTheme } from '@mui/material';
import React from 'react';

const StatusBadge = ({ color, label, fullWidth, minWidth, width, size, uppercase, avatar }) => {
  const theme = useTheme();

  const sWidth = fullWidth ?? true ? '100%' : width ? width + 'px !important' : 'auto';
  const sSize = size ?? 'small';
  const sizeFactor = sSize === 'small' ? 3 : sSize === 'medium' ? 2 : sSize === 'large' ? 1 : sSize === 'extra-large' ? 0 : 0;
  const sMinWidth = minWidth ? minWidth + 'px !important' : '5vw !important';
  const style = {
    height: '25px !important',
    fontSize: theme.typography.fontSize - sizeFactor,
    borderRadius: '4px',
    width: sWidth,
    minWidth: sMinWidth
  };
  avatar = avatar ? avatar : <></>;

  label = uppercase ?? false ? label.toUpperCase() : label;

  return <Chip color={color} label={label} sx={style} avatar={avatar} />;
};

export { StatusBadge };
