import { Link } from 'react-router-dom';
import { ButtonBase } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import config from 'config';
import { activeItem } from 'store/reducers/menu';
import AppLogo from '../logos/AppLogo';
import { useTheme } from '@mui/material/styles';

export const LogoSection = ({ sx, to, size }) => {
  const theme = useTheme();
  const { defaultId } = useSelector((state) => state.menu);
  const dispatch = useDispatch();
  const iSize = size ?? 100;
  const bStyle = { ...sx };
  const lStyle = {
    py: theme.spacing(2.5)
  };

  return (
    <ButtonBase
      disableRipple
      component={Link}
      onClick={() => dispatch(activeItem({ openItem: [defaultId] }))}
      to={!to ? config.defaultPath : to}
      sx={{ ...bStyle }}
    >
      <AppLogo size={iSize} direction={'row'} sx={{ ...lStyle }} />
    </ButtonBase>
  );
};
