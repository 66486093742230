import { IconButton } from '@mui/material';
import { ButtonIcon } from '../icons/ButtonIcon';
import React from 'react';

export const CustomIconButton = ({ color, variant, action, size, icon, iconSize, disabled, ...otherProps }) => {
  let isDisabled = disabled ?? false;

  return (
    <IconButton color={color} variant={variant} onClick={action} size={size} disabled={isDisabled} {...otherProps}>
      <ButtonIcon icon={icon} iconSize={iconSize} />
    </IconButton>
  );
};
