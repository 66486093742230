import { useSelector } from 'react-redux';
import { Avatar } from '@mui/material';
import { getCompleteName, stringAvatar } from '../../utils/common';

const UserImage = ({ width, height }) => {
  const authStatus = useSelector((state) => state.auth.authStatus) ?? {};
  const loggedUser = authStatus.loggedUser ?? {};

  const imageByteArray = loggedUser.mImageListOfBytes ?? [];
  const completeName = getCompleteName(loggedUser.mFirstName, loggedUser.mLastName);

  if (imageByteArray.length !== 0 && imageByteArray !== '') {
    return <Avatar alt={completeName} src={imageByteArray} sx={{ width: width, height: height }} />;
  } else {
    return <Avatar {...stringAvatar(completeName, width, height)} />;
  }
};

export default UserImage;
