// material-ui
import { Box, useMediaQuery } from '@mui/material';

// project import
import ProfileMenu from './ProfileMenu';
import Notification from './Notification';
import MobileSection from './MobileSection';
import SessionProgress from '../../../../components/sessionProgress/SessionProgress';
import GoToDesktop from './GoToDesktop';
import React from 'react';
// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <>
      {!matchesXs && <GoToDesktop />}
      {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}
      <Notification />
      <SessionProgress />
      {!matchesXs && <ProfileMenu />}
      {matchesXs && <MobileSection />}
    </>
  );
};

export default HeaderContent;
