import { combineReducers } from 'redux';
import authReducer from './auth';
import controlPanelReducer from './controPanel';
import errorsReducer from './errors';
import menuReducer from './menu';
import registriesReducer from './registries';
import reportingReducer from './reporting';
import templatesReducer from './templates';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  auth: authReducer,
  controlPanel: controlPanelReducer,
  errors: errorsReducer,
  menu: menuReducer,
  reporting: reportingReducer,
  registries: registriesReducer,
  templates: templatesReducer
});

export default reducers;
