import { Box, Typography, useTheme } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import React, { useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { logout, ping, setSessionExpired, setSessionRemainingTime } from '../../store/reducers/auth';

const SessionProgress = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const maxSessionTime = useSelector((state) => state.auth.maxSessionTime);
  //const maxSessionTime = 1 * 10 * 1000;
  const authStatus = useSelector((state) => state.auth.authStatus);
  const loggedUser = authStatus.loggedUser ?? {};
  const sessionCheckInterval = useSelector((state) => state.auth.sessionCheckInterval);
  const remainingTime = useSelector((state) => state.auth.authStatus.sessionRemainingTime);
  const progress = (remainingTime * 100) / maxSessionTime;

  // 1 * 60 *1000 authContext.maxSessionTime

  let idleTimer = useIdleTimer({
    timeout: maxSessionTime
  });

  useEffect(() => {
    const timer = setInterval(() => {
      let idleRemainingTime = idleTimer.getRemainingTime();
      if (idleRemainingTime >= sessionCheckInterval) {
        const payload = { body: {}, dispatch: dispatch };
        dispatch(ping(payload));
        dispatch(setSessionRemainingTime({ remainingTime: idleRemainingTime }));
      } else {
        const body = {
          mUserID: loggedUser.mUserID,
          mDisconnectReason: 0
        };
        const payload = { body: { ...body }, dispatch: dispatch };
        dispatch(logout(payload)).then(() => {
          dispatch(setSessionExpired());
        });
      }
    }, sessionCheckInterval);
    return () => {
      clearInterval(timer);
    };
  }, [dispatch, idleTimer, sessionCheckInterval, authStatus]);

  const getRemainLabel = () => {
    let mss = remainingTime;
    let mm = Math.floor(mss / 60000);
    mss = mss - 60000 * mm;
    let ss = Math.floor(mss / 1000);
    mm = (mm + 100).toString().substr(1, 2);
    ss = (ss + 100).toString().substr(1, 2);
    return mm + ':' + ss;
  };

  return (
    <Box sx={{ p: theme.spacing(1) }}>
      <LinearProgress variant="determinate" value={progress} />
      <Typography variant="subtitle1">Tempo rimanente {getRemainLabel()} </Typography>
    </Box>
  );
};
export default SessionProgress;
