import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { Box, useMediaQuery } from '@mui/material';
import Drawer from './Drawer';
import Header from './Header';
import { openDrawer } from 'store/reducers/menu';
import { AlertsTree } from '../../components/alerts/AlertsTree';

const LoggedLayout = () => {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authStatus = useSelector((state) => state.auth.authStatus);

  const { drawerOpen } = useSelector((state) => state.menu);

  // drawer toggler
  const [open, setOpen] = useState(drawerOpen);
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  // set media wise responsive drawer
  useEffect(() => {
    // setOpen(!matchDownLG);
    // dispatch(openDrawer({ drawerOpen: !matchDownLG }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownLG]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen]);

  useEffect(() => {
    if (!authStatus.isLogged) navigate('/Login');
  }, [authStatus, navigate]);

  return (
    <>
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} sx={{ width: '100%' }}>
        <Header open={open} handleDrawerToggle={handleDrawerToggle} />
        <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} sx={{ mt: theme.spacing(14) }}>
          {/*<Toolbar />*/}
          <Outlet />
        </Box>
      </Box>
      <AlertsTree />
    </>
  );
};

export default LoggedLayout;
