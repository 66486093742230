// material-ui
import { Box, Typography } from '@mui/material';

// project import
import NavGroup from './NavGroup';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { isDataLoading, isDataNull } from '../../../../../utils/common';
import { fetchDomains } from '../../../../../store/reducers/registries';
import { CircularWaiting } from '../../../../../components/waitings/CircularWaiting';

const Navigation = () => {
  const authStatus = useSelector((state) => state.auth.authStatus);
  const dispatch = useDispatch();
  const domains = useSelector((state) => state.registries.domains);

  const [dataLoaded, setDataLoaded] = useState(false);
  const [itemToSearch] = useState('');

  useEffect(() => {
    if (dataLoaded) return;
    if (!authStatus.isLogged) return;
    const payload = { body: {}, dispatch: dispatch };
    dispatch(fetchDomains(payload)).then(() => {
      setDataLoaded(true);
    });
  }, [dispatch, dataLoaded, domains, authStatus.isLogged]);

  if (!authStatus.isLogged)
    return (
      <Typography key={'no-logged-label'} variant="h6" color="error" align="center">
        No menus
      </Typography>
    );

  const functions_list = authStatus.loggedUser.mProfile.mListOfFunction;
  //.sort((a, b) => (a.mFunctionDescription < b.mFunctionDescription) ? 1 : -1);

  const getFunctionList = (domain) => {
    let filteredFunctions = [];

    if (itemToSearch !== '' && itemToSearch.length >= 3) {
      filteredFunctions = functions_list
        .filter(
          (f) =>
            (f.mFunctionDescription.toLowerCase() === itemToSearch.toLowerCase() &&
              f.mDomain.mDomainName.toLowerCase() === domain.toLowerCase() &&
              f.mIsEnabled) ||
            (f.mDomain.mDomainName.toLowerCase() === itemToSearch.toLowerCase() && f.mIsEnabled)
        )
        .sort((a, b) => (a.mFunctionDescription > b.mFunctionDescription ? 1 : -1));
    } else {
      filteredFunctions = functions_list
        .filter((f) => f.mDomain.mDomainName.toLowerCase() === domain.toLowerCase() && f.mIsEnabled)
        .sort((a, b) => (a.mFunctionName > b.mFunctionName ? 1 : -1));
    }

    filteredFunctions = filteredFunctions.map((item) => {
      return {
        id: item.mFunctionID,
        title: item.mFunctionName,
        type: 'item',
        url: item.mComponentName,
        icon: item.mImageKey,
        target: false
      };
    });
    return filteredFunctions;
  };

  if (isDataNull(domains) || isDataLoading(domains)) return <CircularWaiting />;

  const groups = domains.map((item) => ({
    id: item.mDomainName,
    title: item.mDomainName,
    type: 'group',
    icon: item.mImageKey,
    children: [...getFunctionList(item.mDomainName)]
  }));

  const navGroups = groups.map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });

  return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
};

export default Navigation;
