import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Chip } from '@mui/material';

// project import
import DrawerHeaderStyled from './DrawerHeaderStyled';
import { LogoSection } from '../../../../components/logo/LogoSection';

// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeader = ({ open }) => {
  const theme = useTheme();

  return (
    // only available in paid version
    <DrawerHeaderStyled theme={theme} open={open}>
      <LogoSection size={75} />
      <Chip
        label={process.env.REACT_APP_VERSION}
        size="medium"
        sx={{ height: 16, '& .MuiChip-label': { fontSize: '0.625rem', py: 0.25 } }}
        component="a"
        href="https://github.com/codedthemes/mantis-free-react-admin-template"
        target="_blank"
        clickable
      />
    </DrawerHeaderStyled>
  );
};

DrawerHeader.propTypes = {
  open: PropTypes.bool
};

export default DrawerHeader;
