import React, { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import NotLoggedLayout from 'layout/NotLogged';

// render - login
const AuthLogin = Loadable(lazy(() => import('pages/not-logged/Login')));
const AuthRegister = Loadable(lazy(() => import('pages/not-logged/Register')));
const AuthRecoveryPassword = Loadable(lazy(() => import('pages/not-logged/RecoveryPassword')));
const NotLoggedFillAuditByLink = Loadable(lazy(() => import('pages/not-logged/FillAuditByLink')));

const NotLoggedRoutes = {
  path: '/',
  element: <NotLoggedLayout />,
  children: [
    {
      path: '/Login',
      element: <AuthLogin />
    },
    {
      path: '/Register',
      element: <AuthRegister />
    },
    {
      path: '/RecoveryPassword',
      element: <AuthRecoveryPassword />
    },
    {
      path: '/FillAudit',
      element: <NotLoggedFillAuditByLink />
    }
  ]
};

export default NotLoggedRoutes;
