import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DoWebApiPostCall } from '../../services/AxiosInstance';
import { JSONClone } from '../../utils/common';

export const maxAnswerValue = parseInt(process.env.REACT_APP_MAX_ANSWER_VALUE);

const newAnswerItem = {
  mAnswerItemID: 0
};

const newBlockQuestion = {
  mQuestionTemplateID: 0,
  mAnswer: {},
  mAnswerTypeID: 1,
  mIsMandatory: false,
  mOrder: 0,
  mQuestionText: 'Nuova domanda',
  mScore: 1,
  mTooltipText: 'Suggerimento nuova domanda',
  mIsValidated: false
};

const newProduct = {
  mProductID: 0,
  mProductName: '',
  mDescription: '',
  mTechDescription: '',
  mBarcode: ''
};

const newTemplate = {
  mAuditTemplateID: 0,
  mAuditTemplateName: 'Nuovo template',
  mAuditTemplateCode: '000',
  mProduct: {}
};

const newTemplateBlock = {
  mBlockID: 0,
  mBlockTitle: 'Nuovo blocco',
  mBlockDescription: 'Descrizione nuovo blocco'
};

const newAudit = {
  mCompany: null,
  mAuditTemplate: { mProduct: { mProductID: null } },
  mAudit: { mAuditID: 0 }
};

const newAuditToOpen = {
  mAudit: { mAuditID: 0 },
  mAuditTemplate: {
    mAuditTemplateID: 0,
    mProduct: {
      mProductID: 0
    }
  }
};

// initial state
let initialState = {
  audits: null,
  answerItems: null,
  blockQuestions: null,
  templates: null,
  templateBlocks: null,
  auditToOpen: JSONClone(newAuditToOpen)
};

export const updateAnswer = createAsyncThunk('templates/updateAnswer', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Answer/CreateOrUpdate', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const fetchAnswer = createAsyncThunk('templates/fetchAnswer', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Answer/GetByObject', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const fetchAnswerItems = createAsyncThunk('templates/fetchAnswerItems', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/AnswerItem/GetByObject', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const fetchAnswerItem = createAsyncThunk('templates/fetchAnswerItem', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    if (body.mAnswerItemID !== 0) {
      const res = await DoWebApiPostCall('/AnswerItem/GetByObject', body, payload.dispatch, true);
      if (res.anyError) return rejectWithValue(res);
      if (!res.responseData) return rejectWithValue(res);
      if (res.responseData.length === 0) return rejectWithValue(res);
      return { result: res.responseData[0] };
    } else {
      const retObj = {
        ...JSONClone(newAnswerItem)
      };
      return { result: retObj };
    }
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const updateAnswerItem = createAsyncThunk('templates/updateAnswerItem', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/AnswerItem/CreateOrUpdate', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const insertISOAnswers = createAsyncThunk('templates/insertISOAnswers', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/AnswerItem/CreateISO27001', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const deleteAnswerItem = createAsyncThunk('templates/deleteAnswerItem', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/AnswerItem/Delete', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const fetchAudits = createAsyncThunk('templates/fetchAudits', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Audit/GetByObject', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const createAudit = createAsyncThunk('templates/createAudit', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Audit/Create', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    if (!res.responseData) return rejectWithValue(res);
    if (res.responseData.length === 0) return rejectWithValue(res);
    return { result: res.responseData };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const fetchAudit = createAsyncThunk('templates/fetchAudit', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    if (body.mAuditID !== 0) {
      const res = await DoWebApiPostCall('/Audit/GetByObject', body, payload.dispatch, true);
      if (res.anyError) return rejectWithValue(res);
      if (!res.responseData) return rejectWithValue(res);
      if (res.responseData.length === 0) return rejectWithValue(res);
      return { result: res.responseData[0] };
    } else {
      const retObj = {
        ...JSONClone(newAudit)
      };
      return { result: retObj };
    }
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const updateAudit = createAsyncThunk('templates/updateAudit', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Audit/CreateOrUpdate', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const deleteAudit = createAsyncThunk('templates/deleteAudit', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Audit/Delete', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const beginAudit = createAsyncThunk('templates/beginAudit', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Audit/Develop', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    if (!res.responseData) return rejectWithValue(res);
    return { result: res.responseData };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const calculateAudit = createAsyncThunk('templates/calculateAudit', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Audit/Calculate', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    if (!res.responseData) return rejectWithValue(res);
    return { result: res.responseData };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const completeAudit = createAsyncThunk('templates/completeAudit', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Audit/SetCompleted', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    if (!res.responseData) return rejectWithValue(res);
    return { result: res.responseData };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const downloadAudit = createAsyncThunk('templates/downloadAudit', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Audit/Report', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    if (!res.responseData) return rejectWithValue(res);
    return { result: res.responseData };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const statsAudit = createAsyncThunk('templates/statsAudit', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Audit/Report', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    if (!res.responseData) return rejectWithValue(res);
    return { result: res.responseData };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const shareAudit = createAsyncThunk('templates/shareAudit', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Audit/SendLink', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    if (!res.responseData) return rejectWithValue(res);
    return { result: res.responseData };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const fetchBlockQuestions = createAsyncThunk('templates/fetchBlockQuestions', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/QuestionTemplate/GetByObject', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const fetchBlockQuestion = createAsyncThunk('templates/fetchBlockQuestion', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    if (body.mQuestionTemplateID !== 0) {
      const res = await DoWebApiPostCall('/QuestionTemplate/GetByObject', body, payload.dispatch, true);
      if (res.anyError) return rejectWithValue(res);
      if (!res.responseData) return rejectWithValue(res);
      if (res.responseData.length === 0) return rejectWithValue(res);
      return { result: res.responseData[0] };
    } else {
      const retObj = {
        ...JSONClone(newBlockQuestion),
        mBlock: body.mBlock
      };
      return { result: retObj };
    }
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const updateBlockQuestion = createAsyncThunk('templates/updateBlockQuestion', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/QuestionTemplate/CreateOrUpdate', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const deleteBlockQuestion = createAsyncThunk('templates/deleteBlockQuestion', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/QuestionTemplate/Delete', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const fetchProducts = createAsyncThunk('templates/fetchProducts', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Service/GetByObject', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const fetchProduct = createAsyncThunk('templates/fetchProduct', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    if (body.mProductID !== 0) {
      const res = await DoWebApiPostCall('/Service/GetByObject', body, payload.dispatch, true);
      if (res.anyError) return rejectWithValue(res);
      if (!res.responseData) return rejectWithValue(res);
      if (res.responseData.length === 0) return rejectWithValue(res);
      return { result: res.responseData[0] };
    } else {
      return { result: JSONClone(newProduct) };
    }
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const updateProduct = createAsyncThunk('templates/updateProduct', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Service/CreateOrUpdate', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const switchProduct = createAsyncThunk('templates/switchProduct', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Service/SwitchStatus', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const deleteProduct = createAsyncThunk('templates/deleteProduct', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Service/Delete', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const fetchTemplates = createAsyncThunk('templates/fetchTemplates', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/AuditTemplate/GetByObject', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const fetchTemplate = createAsyncThunk('templates/fetchTemplate', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    if (body.mAuditTemplateID !== 0) {
      const res = await DoWebApiPostCall('/AuditTemplate/GetByObject', body, payload.dispatch, true);
      if (res.anyError) return rejectWithValue(res);
      if (!res.responseData) return rejectWithValue(res);
      if (res.responseData.length === 0) return rejectWithValue(res);
      return { result: res.responseData[0] };
    } else {
      return { result: JSONClone(newTemplate) };
    }
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const updateTemplate = createAsyncThunk('templates/updateTemplate', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/AuditTemplate/CreateOrUpdate', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const deleteTemplate = createAsyncThunk('templates/deleteTemplate', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/AuditTemplate/Delete', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const switchTemplate = createAsyncThunk('templates/switchTemplate', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/AuditTemplate/SwitchStatus', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const cloneTemplate = createAsyncThunk('templates/cloneTemplate', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/AuditTemplate/Clone', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const fetchTemplateBlocks = createAsyncThunk('templates/fetchTemplateBlocks', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Block/GetByObject', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const fetchTemplateBlock = createAsyncThunk('templates/fetchTemplateBlock', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    if (body.mBlockID !== 0) {
      const res = await DoWebApiPostCall('/Block/GetByObject', body, payload.dispatch, true);
      if (res.anyError) return rejectWithValue(res);
      if (!res.responseData) return rejectWithValue(res);
      if (res.responseData.length === 0) return rejectWithValue(res);
      return { result: res.responseData[0] };
    } else {
      const retObj = {
        ...JSONClone(newTemplateBlock),
        mAuditTemplate: body.mAuditTemplate
      };
      return { result: retObj };
    }
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const updateTemplateBlock = createAsyncThunk('templates/updateTemplateBlock', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Block/CreateOrUpdate', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const deleteTemplateBlock = createAsyncThunk('templates/deleteTemplateBlock', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Block/Delete', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const fetchAttachments = createAsyncThunk('templates/fetchAttachments', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Document/GetAttachementList', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const uploadGenericDocument = createAsyncThunk('templates/uploadGenericDocument', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Document/UploadGenericDocument', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const deleteGenericDocument = createAsyncThunk('templates/uploadGenericDocument', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Document/Delete', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const downloadGenericDocument = createAsyncThunk('templates/downloadGenericDocument', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Document/DownloadGenericDocument', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

const templatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    setAuditToOpen(state, action) {
      state.auditToOpen = action.payload;
    },
    resetAuditToOpen(state) {
      state.auditToOpen = JSONClone(newAuditToOpen);
    },
    resetAnswerItems(state) {
      state.answerItems = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAnswerItems.pending, (state) => {
      state.answerItems = 'loading';
    });
    builder.addCase(fetchAnswerItems.fulfilled, (state, { payload }) => {
      const res = payload.result;
      state.answerItems = [...res.responseData];
    });
    builder.addCase(fetchAnswerItems.rejected, (state) => {
      state.answerItems = [];
    });
    builder.addCase(fetchAudit.pending, (state) => {
      state.products = 'loading';
    });
    builder.addCase(fetchAudits.fulfilled, (state, { payload }) => {
      const res = payload.result;
      const arr = [...res.responseData] ?? [];
      state.audits = arr.sort((a, b) => (a.mAuditName > b.mAuditName ? -1 : 1));
    });
    builder.addCase(fetchAudit.rejected, (state) => {
      state.products = [];
    });
    builder.addCase(fetchBlockQuestions.pending, (state) => {
      state.blockQuestions = 'loading';
    });
    builder.addCase(fetchBlockQuestions.fulfilled, (state, { payload }) => {
      const res = payload.result;
      state.blockQuestions = [...res.responseData];
    });
    builder.addCase(fetchBlockQuestions.rejected, (state) => {
      state.blockQuestions = [];
    });
    builder.addCase(fetchProducts.pending, (state) => {
      state.products = 'loading';
    });
    builder.addCase(fetchProducts.fulfilled, (state, { payload }) => {
      const res = payload.result;
      state.products = [...res.responseData];
    });
    builder.addCase(fetchProducts.rejected, (state) => {
      state.products = [];
    });
    builder.addCase(fetchTemplates.pending, (state) => {
      state.templates = 'loading';
    });
    builder.addCase(fetchTemplates.fulfilled, (state, { payload }) => {
      const res = payload.result;
      state.templates = [...res.responseData];
    });
    builder.addCase(fetchTemplates.rejected, (state) => {
      state.templates = [];
    });
    builder.addCase(fetchTemplateBlocks.pending, (state) => {
      state.templateBlocks = 'loading';
    });
    builder.addCase(fetchTemplateBlocks.fulfilled, (state, { payload }) => {
      const res = payload.result;
      state.templateBlocks = [...res.responseData];
    });
    builder.addCase(fetchTemplateBlocks.rejected, (state) => {
      state.templateBlocks = [];
    });
  }
});

export const { setAuditToOpen, resetAuditToOpen, resetAnswerItems } = templatesSlice.actions;
export default templatesSlice.reducer;
