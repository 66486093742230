import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DoWebApiPostCall } from '../../services/AxiosInstance';
import { JSONClone } from '../../utils/common';
import moment from 'moment';

const newLog = {
  mLogID: 0,
  mLogMessage: '',
  mLogDate: moment()
};

// initial state
let initialState = {
  connectedUsers: null,
  logs: null
};

export const fetchConnectedUsers = createAsyncThunk('controlPanel/fetchConnectedUsers', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Session/GetByObject', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const fetchLogs = createAsyncThunk('controlPanel/fetchLogs', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Log/GetByObject', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const fetchLog = createAsyncThunk('controlPanel/fetchLog', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    if (body.mLogID !== 0) {
      const res = await DoWebApiPostCall('/Log/GetByObject', body, payload.dispatch, true);
      if (res.anyError) return rejectWithValue(res);
      if (!res.responseData) return rejectWithValue(res);
      if (res.responseData.length === 0) return rejectWithValue(res);
      return { result: res.responseData[0] };
    } else {
      return { result: JSONClone(newLog) };
    }
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

const controlPanelSlice = createSlice({
  name: 'controlPanel',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchConnectedUsers.pending, (state) => {
      state.connectedUsers = 'loading';
    });
    builder.addCase(fetchConnectedUsers.fulfilled, (state, { payload }) => {
      const res = payload.result;
      state.connectedUsers = [...res.responseData];
    });
    builder.addCase(fetchConnectedUsers.rejected, (state) => {
      state.connectedUsers = [];
    });
    builder.addCase(fetchLogs.pending, (state) => {
      state.logs = 'loading';
    });
    builder.addCase(fetchLogs.fulfilled, (state, { payload }) => {
      const res = payload.result;
      state.logs = [...res.responseData];
    });
    builder.addCase(fetchLogs.rejected, (state) => {
      state.logs = [];
    });
  }
});

// export const {} = controlPanelSlice.actions;
export default controlPanelSlice.reducer;
