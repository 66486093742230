import PropTypes from 'prop-types';
import { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

// assets
import { EditOutlined, LogoutOutlined } from '@ant-design/icons';
import { CircularWaiting } from '../../../../../components/waitings/CircularWaiting';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

const ProfileTab = ({ handleMenuClose, handleLogout }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const loggedUser = useSelector((state) => state.auth.authStatus.loggedUser);

  const [selectedIndex] = useState(0);

  const handleListItemClick = (event, index) => {
    switch (index) {
      case 0:
        navigate('/User/Profile');
        break;
      default:
      // code block
    }
    handleMenuClose(event);
  };

  if (!loggedUser.mProfile) return <CircularWaiting />;

  return (
    <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32, color: theme.palette.grey[500] } }}>
      <ListItemButton selected={selectedIndex === 0} onClick={(event) => handleListItemClick(event, 0)}>
        <ListItemIcon>
          <EditOutlined />
        </ListItemIcon>
        <ListItemText primary="Edit Profile" />
      </ListItemButton>
      <ListItemButton selected={selectedIndex === 2} onClick={handleLogout}>
        <ListItemIcon>
          <LogoutOutlined />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItemButton>
    </List>
  );
};

ProfileTab.propTypes = {
  handleLogout: PropTypes.func
};

export default ProfileTab;
