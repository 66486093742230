import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DoWebApiPostCall } from '../../services/AxiosInstance';
import { JSONClone } from '../../utils/common';

const newReport = {
  mReportID: 0,
  mReportCode: '000',
  mLabel: 'Nuovo report',
  mReportGroup: 'Nuovo gruppo',
  mQuery: 'SELECT * FROM'
};

// initial state
let initialState = {
  reportsInputModes: null,
  reportsOutputModes: null,
  reportsGroups: null,
  reports: null
};

export const fetchReportsGroups = createAsyncThunk('controlPanel/fetchReportsGroups', async (payload, { rejectWithValue }) => {
  let body = { mItemizedListID: 51 };
  try {
    const res = await DoWebApiPostCall('/ItemizedListValue/GetByObject', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const fetchReportsInputModes = createAsyncThunk('controlPanel/fetchReportsInputModes', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/InputMode/GetByObject', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const fetchReportsOutputModes = createAsyncThunk('controlPanel/ReportsOutputModes', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/OutputMode/GetByObject', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const fetchReports = createAsyncThunk('controlPanel/fetchReports', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Report/GetByObject', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const fetchReport = createAsyncThunk('controlPanel/fetchReport', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    if (body.mReportID !== 0) {
      const res = await DoWebApiPostCall('/Report/GetByObject', body, payload.dispatch, true);
      if (res.anyError) return rejectWithValue(res);
      if (!res.responseData) return rejectWithValue(res);
      if (res.responseData.length === 0) return rejectWithValue(res);
      return { result: res.responseData[0] };
    } else {
      return { result: JSONClone(newReport) };
    }
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const updateReport = createAsyncThunk('registries/updateReport', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Report/CreateOrUpdate', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const deleteReport = createAsyncThunk('registries/deleteReport', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Report/Delete', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const clearReport = createAsyncThunk('registries/clearReport', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Report/Clear', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

const reportinglSlice = createSlice({
  name: 'reporting',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchReportsGroups.pending, (state) => {
      state.reportsGroups = 'loading';
    });
    builder.addCase(fetchReportsGroups.fulfilled, (state, { payload }) => {
      const res = payload.result;
      state.reportsGroups = [...res.responseData];
    });
    builder.addCase(fetchReportsGroups.rejected, (state) => {
      state.reportsGroups = [];
    });
    builder.addCase(fetchReportsInputModes.pending, (state) => {
      state.reportsInputModes = 'loading';
    });
    builder.addCase(fetchReportsInputModes.fulfilled, (state, { payload }) => {
      const res = payload.result;
      state.reportsInputModes = [...res.responseData];
    });
    builder.addCase(fetchReportsInputModes.rejected, (state) => {
      state.reportsInputModes = [];
    });
    builder.addCase(fetchReportsOutputModes.pending, (state) => {
      state.reportsOutputModes = 'loading';
    });
    builder.addCase(fetchReportsOutputModes.fulfilled, (state, { payload }) => {
      const res = payload.result;
      state.reportsOutputModes = [...res.responseData];
    });
    builder.addCase(fetchReportsOutputModes.rejected, (state) => {
      state.reportsOutputModes = [];
    });
    builder.addCase(fetchReports.pending, (state) => {
      state.reports = 'loading';
    });
    builder.addCase(fetchReports.fulfilled, (state, { payload }) => {
      const res = payload.result;
      state.reports = [...res.responseData];
    });
    builder.addCase(fetchReports.rejected, (state) => {
      state.reports = [];
    });
  }
});

// export const {} = controlPanelSlice.actions;
export default reportinglSlice.reducer;
