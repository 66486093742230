import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DoWebApiPostCall } from '../../services/AxiosInstance';
import { JSONClone, nullDateTime } from '../../utils/common';
import moment from 'moment';

const newCompany = {
  mCompanyID: 0,
  mCompanyName: 'Nuova Azienda',
  mAddress: '',
  mTelefono: '',
  mCodiceFisale: '',
  mPartitaIVA: '',
  mCity: null,
  mCAP: '',
  mLegalMail: '',
  mMail: '',
  mParent: null,
  mType: 'COMPANY',
  mStartDate: moment(nullDateTime),
  mDurata: 0,
  mImporto: 0
};

const newDepartment = {
  mDepartmentID: 0,
  mDepartmentName: 'Nuovo dipartimento',
  mCity: null
};

const newDomain = {
  mDomainID: 0,
  mDomainName: 'Nuovo dominio'
};

const newProfile = {
  mProfileID: 0,
  mProfileName: 'Nuovo profilo',
  mAvailableToRegistration: false
};

const newSite = {
  mSiteID: 0,
  mSiteName: 'Nuova sede',
  mSiteCode: '000',
  mCity: null
};

const newSystemFunction = {
  mFunctionID: 0,
  mFunctionName: 'Nuova funzione',
  mFunctionDescription: 'Nuova funzione',
  mComponentName: '',
  mProfileID: 0,
  mShowOnDesktop: false,
  mDomain: null
};

const newSystemParameter = {
  mParameterID: 0,
  mParamName: 'Nuovo parametro'
};

const newUser = {
  mUserID: 0,
  mUserName: 'Username (email)',
  mMail: '',
  mMobilePhone: '',
  mFirstName: 'Nome',
  mLastName: '',
  mHeaderReferto: '',
  mFooterReferto: '',
  mProfile: null,
  mCompany: null
};

const newItemizedList = {
  mItemizedListID: 0,
  mSQLName: "Nuovo Itemized List"
};

// initial state
let initialState = {
  cities: null,
  companies: null,
  departments: null,
  domains: null,
  itemizedLists: null,
  profiles: null,
  sites: null,
  systemParameters: null,
  systemFunctions: null,
  users: null,
  fileTemplates: null
};

const normalizeCity = (city) => {
  let name = '';
  if (city.mCityName) name += city.mCityName.toUpperCase();
  if (city.mTargaProvincia) name += ' (' + city.mTargaProvincia.toUpperCase() + ')';
  return { ...city, mCompleteName: name };
};

export const fetchCities = createAsyncThunk('registries/fetchCities', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Cities/GetByObject', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const fetchCompanies = createAsyncThunk('registries/fetchCompanies', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Company/GetByObject', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const fetchCompany = createAsyncThunk('registries/fetchCompany', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    if (body.mCompanyID !== 0) {
      const res = await DoWebApiPostCall('/Company/GetByObject', body, payload.dispatch, true);
      if (res.anyError) return rejectWithValue(res);
      if (!res.responseData) return rejectWithValue(res);
      if (res.responseData.length === 0) return rejectWithValue(res);
      return { result: res.responseData[0] };
    } else {
      return { result: JSONClone(newCompany) };
    }
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const updateCompany = createAsyncThunk('registries/updateCompany', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Company/CreateOrUpdate', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const deleteCompany = createAsyncThunk('registries/deleteCompany', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Company/Delete', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const fetchDepartments = createAsyncThunk('registries/fetchDepartments', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Department/GetByObject', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const fetchDepartment = createAsyncThunk('registries/fetchDepartment', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    if (body.mDepartmentID !== 0) {
      const res = await DoWebApiPostCall('/Department/GetByObject', body, payload.dispatch, true);
      if (res.anyError) return rejectWithValue(res);
      if (!res.responseData) return rejectWithValue(res);
      if (res.responseData.length === 0) return rejectWithValue(res);
      return { result: res.responseData[0] };
    } else {
      return { result: JSONClone(newDepartment) };
    }
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const updateDepartment = createAsyncThunk('registries/updateDepartment', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Department/CreateOrUpdate', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const deleteDepartment = createAsyncThunk('registries/deleteDepartment', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Department/Delete', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const fetchDomains = createAsyncThunk('registries/fetchDomains', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Domain/GetByObject', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const fetchDomain = createAsyncThunk('registries/fetchDomain', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    if (body.mDomainID !== 0) {
      const res = await DoWebApiPostCall('/Domain/GetByObject', body, payload.dispatch, true);
      if (res.anyError) return rejectWithValue(res);
      if (!res.responseData) return rejectWithValue(res);
      if (res.responseData.length === 0) return rejectWithValue(res);
      return { result: res.responseData[0] };
    } else {
      return { result: JSONClone(newDomain) };
    }
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const updateDomain = createAsyncThunk('registries/updateDomain', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Domain/CreateOrUpdate', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const deleteDomain = createAsyncThunk('registries/deleteDomain', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Domain/Delete', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const fetchItemizedLists = createAsyncThunk('registries/fetchItemizedLists', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/ItemizedList/GetByObject', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const fetchItemizedList = createAsyncThunk('registries/fetchItemizedList', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    if (body.mItemizedListID !== 0) {
      const res = await DoWebApiPostCall('/ItemizedList/GetByObject', body, payload.dispatch, true);
      if (res.anyError) return rejectWithValue(res);
      if (!res.responseData) return rejectWithValue(res);
      if (res.responseData.length === 0) return rejectWithValue(res);
      return { result: res.responseData[0] };
    } else {
      return { result: JSONClone(newItemizedList) };
    }
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const updateItemizedList = createAsyncThunk('registries/updateItemizedList', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/ItemizedList/CreateOrUpdate', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const deleteItemizedList = createAsyncThunk('registries/deleteItemizedList', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/ItemizedList/Delete', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const fetchItemizedListValues = createAsyncThunk('registries/fetchItemizedListValues', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/ItemizedListValue/GetByObject', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const deleteItemizedListValue = createAsyncThunk('registries/deleteItemizedListValue', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/ItemizedListValue/Delete', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const updateItemizedListValue = createAsyncThunk('registries/updateItemizedListValue', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/ItemizedListValue/CreateOrUpdate', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const fetchProfiles = createAsyncThunk('registries/fetchProfiles', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Profile/GetByObject', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const fetchProfile = createAsyncThunk('registries/fetchProfile', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    if (body.mProfileID !== 0) {
      const res = await DoWebApiPostCall('/Profile/GetByObject', body, payload.dispatch, true);
      if (res.anyError) return rejectWithValue(res);
      if (!res.responseData) return rejectWithValue(res);
      if (res.responseData.length === 0) return rejectWithValue(res);
      return { result: res.responseData[0] };
    } else {
      return { result: JSONClone(newProfile) };
    }
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const updateProfile = createAsyncThunk('registries/updateProfile', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Profile/CreateOrUpdate', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const deleteProfile = createAsyncThunk('registries/deleteProfile', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Profile/Delete', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const addProfileFunction = createAsyncThunk('registries/addProfileFunction', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Profile/AddFunction', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const removeProfileFunction = createAsyncThunk('registries/removeProfileFunction', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Profile/RemoveFunction', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const fetchSites = createAsyncThunk('registries/fetchSites', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Site/GetByObject', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const fetchSite = createAsyncThunk('registries/fetchSite', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    if (body.mSiteID !== 0) {
      const res = await DoWebApiPostCall('/Site/GetByObject', body, payload.dispatch, true);
      if (res.anyError) return rejectWithValue(res);
      if (!res.responseData) return rejectWithValue(res);
      if (res.responseData.length === 0) return rejectWithValue(res);
      return { result: res.responseData[0] };
    } else {
      return { result: JSONClone(newSite) };
    }
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const updateSite = createAsyncThunk('registries/updateSite', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Site/CreateOrUpdate', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const deleteSite = createAsyncThunk('registries/deleteSite', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Site/Delete', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const fetchSystemParameters = createAsyncThunk('registries/fetchSystemParameters', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Parameter/GetByObject', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const fetchSystemParameter = createAsyncThunk('registries/fetchSystemParameter', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    if (body.mParameterID !== 0) {
      const res = await DoWebApiPostCall('/Parameter/GetByObject', body, payload.dispatch, true);
      if (res.anyError) return rejectWithValue(res);
      if (!res.responseData) return rejectWithValue(res);
      if (res.responseData.length === 0) return rejectWithValue(res);
      return { result: res.responseData[0] };
    } else {
      return { result: JSONClone(newSystemParameter) };
    }
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const updateSystemParameter = createAsyncThunk('registries/updateSystemParameter', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Parameter/CreateOrUpdate', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const deleteSystemParameter = createAsyncThunk('registries/deleteSystemParameter', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Parameter/Delete', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const fetchSystemFunctions = createAsyncThunk('registries/fetchSystemFunctions', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Function/GetByObject', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const fetchSystemFunction = createAsyncThunk('registries/fetchSystemFunction', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    if (body.mFunctionID !== 0) {
      const res = await DoWebApiPostCall('/Function/GetByObject', body, payload.dispatch, true);
      if (res.anyError) return rejectWithValue(res);
      if (!res.responseData) return rejectWithValue(res);
      if (res.responseData.length === 0) return rejectWithValue(res);
      return { result: res.responseData[0] };
    } else {
      return { result: JSONClone(newSystemFunction) };
    }
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const updateSystemFunction = createAsyncThunk('registries/updateSystemFunction', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Function/CreateOrUpdate', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const deleteSystemFunction = createAsyncThunk('registries/deleteSystemFunction', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Function/Delete', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const fetchUsers = createAsyncThunk('registries/fetchUsers', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Users/GetByObject', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const fetchUser = createAsyncThunk('registries/fetchUser', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    if (body.mUserID !== 0) {
      const res = await DoWebApiPostCall('/Users/GetByObject', body, payload.dispatch, true);
      if (res.anyError) return rejectWithValue(res);
      if (!res.responseData) return rejectWithValue(res);
      if (res.responseData.length === 0) return rejectWithValue(res);
      return { result: res.responseData[0] };
    } else {
      return { result: JSONClone(newUser) };
    }
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const updateUser = createAsyncThunk('registries/updateUser', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Users/CreateOrUpdate', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const deleteUser = createAsyncThunk('registries/deleteUser', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Users/Delete', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

const registriesSlice = createSlice({
  name: 'registries',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCities.pending, (state) => {
      state.cities = 'loading';
    });
    builder.addCase(fetchCities.fulfilled, (state, { payload }) => {
      const res = payload.result;
      let recs = res.responseData.map((city) => {
        return normalizeCity(city);
      });
      state.cities = [...recs];
    });
    builder.addCase(fetchCities.rejected, (state) => {
      state.cities = [];
    });
    builder.addCase(fetchCompanies.pending, (state) => {
      state.companies = 'loading';
    });
    builder.addCase(fetchCompanies.fulfilled, (state, { payload }) => {
      const res = payload.result;
      state.companies = [...res.responseData];
    });
    builder.addCase(fetchCompanies.rejected, (state) => {
      state.companies = [];
    });
    builder.addCase(fetchDepartments.pending, (state) => {
      state.departments = 'loading';
    });
    builder.addCase(fetchDepartments.fulfilled, (state, { payload }) => {
      const res = payload.result;
      state.departments = [...res.responseData];
    });
    builder.addCase(fetchDepartments.rejected, (state) => {
      state.departments = [];
    });
    builder.addCase(fetchDomains.pending, (state) => {
      state.domains = 'loading';
    });
    builder.addCase(fetchDomains.fulfilled, (state, { payload }) => {
      const res = payload.result;
      state.domains = [...res.responseData];
    });
    builder.addCase(fetchDomains.rejected, (state) => {
      state.domains = [];
    });
    builder.addCase(fetchItemizedLists.pending, (state) => {
      state.itemizedLists = 'loading';
    });
    builder.addCase(fetchItemizedLists.fulfilled, (state, { payload }) => {
      const res = payload.result;
      state.itemizedLists = [...res.responseData];
    });
    builder.addCase(fetchItemizedLists.rejected, (state) => {
      state.itemizedLists = [];
    });
    builder.addCase(fetchProfiles.pending, (state) => {
      state.profiles = 'loading';
    });
    builder.addCase(fetchProfiles.fulfilled, (state, { payload }) => {
      const res = payload.result;
      state.profiles = [...res.responseData];
    });
    builder.addCase(fetchProfiles.rejected, (state) => {
      state.profiles = [];
    });
    builder.addCase(fetchSites.pending, (state) => {
      state.sites = 'loading';
    });
    builder.addCase(fetchSites.fulfilled, (state, { payload }) => {
      const res = payload.result;
      state.sites = [...res.responseData];
    });
    builder.addCase(fetchSites.rejected, (state) => {
      state.sites = [];
    });
    builder.addCase(fetchSystemParameters.pending, (state) => {
      state.systemParameters = 'loading';
    });
    builder.addCase(fetchSystemParameters.fulfilled, (state, { payload }) => {
      const res = payload.result;
      state.systemParameters = [...res.responseData];
    });
    builder.addCase(fetchSystemParameters.rejected, (state) => {
      state.systemParameters = [];
    });
    builder.addCase(fetchSystemFunctions.pending, (state) => {
      state.systemFunctions = 'loading';
    });
    builder.addCase(fetchSystemFunctions.fulfilled, (state, { payload }) => {
      const res = payload.result;
      state.systemFunctions = [...res.responseData];
    });
    builder.addCase(fetchSystemFunctions.rejected, (state) => {
      state.systemFunctions = [];
    });
    builder.addCase(fetchUsers.pending, (state) => {
      state.users = 'loading';
    });
    builder.addCase(fetchUsers.fulfilled, (state, { payload }) => {
      const res = payload.result;
      state.users = [...res.responseData];
    });
    builder.addCase(fetchUsers.rejected, (state) => {
      state.users = [];
    });
  }
});

// export const { setSessionExpired, setSessionRemainingTime } = authSlice.actions;
export default registriesSlice.reducer;
