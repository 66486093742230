import { useMemo } from 'react';
import { createTheme } from '@mui/material/styles';
import Palette from './Palette';
import Typography from './Typography';
import CustomShadows from './Shadows';
import componentsOverride from './overrides';

export const PrimaryTheme = () => {
  const theme = Palette('light', 'default');

  const themeTypography = Typography(`'Titillium Web', sans-serif`);

  const themeCustomShadows = useMemo(() => CustomShadows(theme), [theme]);

  const themeBreakPoints = {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1266,
      xl: 1536
    }
  };

  const themeOptions = useMemo(
    () => ({
      palette: theme.palette,
      typography: themeTypography,
      customShadows: themeCustomShadows,
      breakpoints: themeBreakPoints,
      direction: 'ltr',
      mixins: {
        toolbar: {
          minHeight: 60,
          paddingTop: 8,
          paddingBottom: 8
        }
      }
    }),
    [theme, themeTypography, themeCustomShadows, themeBreakPoints]
  );

  const primaryTheme = createTheme(themeOptions);
  primaryTheme.components = componentsOverride(primaryTheme);

  return primaryTheme;
};
