import Routes from 'routes';
import ScrollTop from 'components/ScrollTop';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { PrimaryTheme } from './themes/Theme';

const App = () => {
  const primaryTheme = PrimaryTheme();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={primaryTheme}>
        <CssBaseline />
        <ScrollTop>
          <Routes />
        </ScrollTop>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
export default App;
