// import { alpha } from '@mui/material';

export default function InputBase(theme) {
  const muiFocused = {
    // border: '1px solid ' + theme.palette.primary.light,
    // boxShadow: `0 0 0 2px ${alpha(theme.palette.primary.main, 0.2)}`,
    // '&.MuiOutlinedInput-notchedOutline': {
    //   border: `1px solid ${theme.palette.primary.light}`
    // }
  };

  return {
    MuiDisabled: {
      styleOverrides: {
        root: {}
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          minHeight: '6vh !important',
          border: '0.05rem solid ' + theme.palette.grey[200],
          '&.Mui-focused': { ...muiFocused }
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused': { ...muiFocused },
          '&.Mui-disabled': {
            color: theme.palette.common.orange + ' !important'
          }
        }
      }
    },
    MuiFocused: {
      styleOverrides: {
        root: { ...muiFocused }
      }
    }
  };
}
