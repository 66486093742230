// material-ui
import { Box, FormControl } from '@mui/material';

// assets
import { ActionButton } from '../../../../components/buttons/ActionButton';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { openDrawer } from '../../../../store/reducers/menu';
import { useDispatch } from 'react-redux';

// ==============================|| HEADER CONTENT - SEARCH ||============================== //

const GoToDesktop = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Box sx={{ width: '100%', ml: { xs: 0, md: 1 } }}>
      <FormControl sx={{ width: { xs: '100%', md: 224 } }}>
        <ActionButton
          color="primary"
          variant="contained"
          action={() => {
            navigate('/Home');
            dispatch(openDrawer({ drawerOpen: false }));
          }}
          size="medium"
          icon={'home'}
          iconSize="small"
          tooltip={'Vai al desktop'}
          label={'Desktop'}
          type="submit"
          disabled={false}
          loading={false}
        />
      </FormControl>
    </Box>
  );
};

export default GoToDesktop;
